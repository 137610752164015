import i18next from 'i18next';
import i18nextBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isChinaUser } from './isChina';

const domain = isChinaUser() ? '.garmin.cn' : '.garmin.com';

i18next
    .use(i18nextBackend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
        {
            ns: [],
            defaultNS: [],
            fallbackLng: {
                'nb-NO': ['no'],
                'in-ID': ['id-ID', 'en'],
                in: ['id'],
                default: ['en']
            },
            keySeparator: false,
            interpolation: {
                prefix: '{', // default prefix is '{{' but we use single brackets
                suffix: '}' // default suffix is '}}' but we use single brackets
            },
            backend: {
                loadPath: (lng, ns) => `/web-translations/${ns}/${ns}_${lng}.json`,
                queryStringParams: { v: '3.0.3' }
            },
            react: {},
            detection: {
                caches: ['cookie'],
                lookupCookie: 'GarminUserPrefs',
                cookieDomain: domain
            },
            debug: true
        },
        () => {
            switch (i18next.language.toLowerCase()) {
                case 'ar':
                    i18next.changeLanguage('ar-AR');
                    break;
                case 'bg':
                    i18next.changeLanguage('bg-BG');
                    break;
                case 'vi':
                    i18next.changeLanguage('vi-VN');
                    break;
                case 'tr':
                    i18next.changeLanguage('tr-TR');
                    break;
                case 'et':
                    i18next.changeLanguage('et-EE');
                    break;
                case 'uk':
                    i18next.changeLanguage('uk-UA');
                    break;
                case 'lv':
                    i18next.changeLanguage('lv-LV');
                    break;
                case 'lt':
                    i18next.changeLanguage('lt-LT');
                    break;
                case 'th':
                    i18next.changeLanguage('th-TH');
                    break;
                case 'ko':
                    i18next.changeLanguage('ko-KR');
                    break;
                case 'id':
                    i18next.changeLanguage('in-ID');
                    break;
                case 'ms':
                    i18next.changeLanguage('ms-MY');
                    break;
                case 'cs':
                    i18next.changeLanguage('cs-CZ');
                    break;
                case 'da':
                    i18next.changeLanguage('da-DK');
                    break;
                case 'de':
                    i18next.changeLanguage('de-DE');
                    break;
                case 'en':
                    i18next.changeLanguage('en-US');
                    break;
                case 'es':
                    i18next.changeLanguage('es-ES');
                    break;
                case 'fr':
                    i18next.changeLanguage('fr-FR');
                    break;
                case 'hr':
                    i18next.changeLanguage('hr-HR');
                    break;
                case 'it':
                    i18next.changeLanguage('it-IT');
                    break;
                case 'hu':
                    i18next.changeLanguage('hu-HU');
                    break;
                case 'nl':
                    i18next.changeLanguage('nl-NL');
                    break;
                case 'nb':
                    i18next.changeLanguage('nb-NO');
                    break;
                case 'pl':
                    i18next.changeLanguage('pl-PL');
                    break;
                case 'pt-br':
                    i18next.changeLanguage('pt-BR');
                    break;
                case 'pt-pt':
                    i18next.changeLanguage('pt-PT');
                    break;
                case 'ro':
                    i18next.changeLanguage('ro-RO');
                    break;
                case 'sk':
                    i18next.changeLanguage('sk-SK');
                    break;
                case 'sl':
                    i18next.changeLanguage('sl-SI');
                    break;
                case 'fi':
                    i18next.changeLanguage('fi-FI');
                    break;
                case 'sv':
                    i18next.changeLanguage('sv-SE');
                    break;
                case 'he':
                    i18next.changeLanguage('iw-IL');
                    break;
                case 'el':
                    i18next.changeLanguage('el-GR');
                    break;
                case 'ru':
                    i18next.changeLanguage('ru-RU');
                    break;
                case 'zh-cn':
                    i18next.changeLanguage('zh-CN');
                    break;
                case 'zh-tw':
                    i18next.changeLanguage('zh-TW');
                    break;
                case 'ja':
                    i18next.changeLanguage('ja-JP');
                    break;
            }
        }
    );

export default i18next;
